export const app = {
  name: 'Welcome to Retirement Edge',
  environment: 'development',
};

export const msgs = {
  success: 'Operation successful.',
  error: 'Operation failed.',
  noRows: 'No records to display.',
  authError: 'Your session has expired. Please log in again.',
  fieldError: 'This field is required.',
};

export const conf = {
  // serverURL: "https://dev-api.retirement-edge.com/api/1/",
  serverURL: 'https://app-api-recore-prod-centralus.azurewebsites.net/api/1/',
  idleTimeout: 10,
};

export const domainUrl = {
  local: 'localhost:3001',
  dev: 'dev-annuitant-ui.retirement-edge.com',
  stage: 'stage-annuitant-ui.retirement-edge.com',
  production: 'annuitant-ui.retirement-edge.com',
};

export const pagination = {
  length: 20,
  list: [20, 30, 40, 50],
};

export const statusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

export const CognitoFormAccountId = '9LE9gkNsKEqE0uCOyZZkrw';

export const RETIREMENT_AGE = 67;
export const GROWTH_RATE = 0.02;
export const INACTIVE_TIMEOUT = conf.idleTimeout * 60 * 1000;
